import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { redirect, useNavigate } from "react-router-dom";

import {
    detect,
    login,
    logout,
    transChain
} from "../services/ethereum"
import { getBalance, deposit } from "../services/contract"
import { shortHash } from "../utils/utils"

import './charge.scss'

export default function Charge(){
    const dispatch = useDispatch(); 
    const navigate = useNavigate();

    const [account, setAccount] = useState('');
    const [balance, setBalance] = useState(0);
    const [amount, setAmount] = useState(0);
    const [toAddress, setToAddress] = useState('');
    const [isDeposit, setIsDeposit] = useState(false);

    const connect = () =>{
        transChain(function(){
            login(function(account){ 
                setAccount(account) 
                getBalance(account, function(v){
                    setBalance(v);
                });
            })
        });
    }

    const depositStart = () =>{
        deposit({ account, amount, toAddress }, function(res){
            console.log('depositStart', res);
            setIsDeposit(true);
            setTimeout(()=>{
                setIsDeposit(false);
            }, 10*1000);
        });
    }

    useEffect(()=>{
        let url = window.location.href;
        let toValue = url.split('to=')[1] || '';
        setToAddress(toValue);
    }, []);

	return (
		<div className="app-charge-main">
			<h1 className="app-charge-title">Deposit USDT in your Werewolf.AI wallet</h1>

            <div className="app-charge-to" title={ toAddress }>to: { toAddress ? shortHash(toAddress, 10) : '' }</div>
            <div className="app-charge-from" title={ account }>from: { account ? shortHash(account, 10) : '' }</div>

            {account && <div className="app-charge-amount">
                <input type="number" value={ amount } onChange={ (e)=>{ 
                    let v = Math.min(e.target.value, balance);
                    setAmount(v); 
                } } />
                <span className="max" onClick={()=>{ setAmount(balance) }}>max</span>
            </div>}

            {isDeposit && <div className="app-charge-deposit">Deposit successful.</div>}

            <div className="app-charge-btns">
                {account 
                    ? <button onClick={ ()=>{ 
                        if(amount <= 0){
                            return;
                        } 
                        depositStart() 
                        } }>deposit</button>
                    : <button onClick={ ()=>{ connect() } }>connect</button>
                }
            </div>

            {account && <div className="app-charge-balance">Werewolf.AI Balance: { balance } FAUSDT</div>}
		</div>
	)
}