const { localStorage, sessionStorage } = window;

function timeFormat(time){
    //time == s; 1D11H56M23S;
    if(!time){
        return '';
    }
    if(time === 0){
        return 0;
    }
    time = Math.round(time);
    let d = Math.floor(time/3600/24);
    let h = Math.floor((time/3600 - d*24));
    let m = Math.floor((time/60 - d*24*60 - h*60));
    let s = Math.floor((time - d*3600*24 - h*3600 - m*60));
    let str = s + 'S ';
    if(m > 0){
        str = m + 'M ' + str;
    }
    if(h > 0){
        str = h + 'H ' + str;
    }
    if(d > 0){
        str = d + 'D ' + str;
    }
    return  str;
}

function numberFormat(n){
    //1000 == 1.00K, >1000000 == 1.00M
    if(!n || n < 0){
        return 0;
    }
    let d = n > 0 ? '' : '-';
    n = Math.abs(n);
    if(n >= 1000000000){
        return d + '9999M';
    }if(n >= 1000000){
        return d +  Math.ceil(n/10000)/100 + 'M';
    }else if(n >= 1000){
        return d + Math.ceil(n/10)/100 + 'K';
    }else{
        return d + Math.ceil(n);
    }
}

function dateFormat(date, fmt) {
    fmt = fmt || 'yyyy/MM/dd hh:mm:ss';
    var dateObj;
    if(date){
        dateObj = new Date(date);
    }else{
        return '';
    }

    var o = {
        "M+" : dateObj.getMonth()+1,                 
        "d+" : dateObj.getDate(),                    
        "h+" : dateObj.getHours(),                   
        "m+" : dateObj.getMinutes(),                 
        "s+" : dateObj.getSeconds(),                 
        "q+" : Math.floor((dateObj.getMonth()+3)/3), 
        "S"  : dateObj.getMilliseconds()             
    };
    if(/(y+)/.test(fmt)) {
        fmt=fmt.replace(RegExp.$1, (dateObj.getFullYear()+"").substr(4 - RegExp.$1.length));
    }
    for(var k in o) {
        if(new RegExp("("+ k +")").test(fmt)){
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
        }
    }
    return fmt;
}

function subs(temp, data, regexp){
    if(!(Object.prototype.toString.call(data) === "[object Array]")) data = [data];
    var ret = [];
    for (var i = 0, j = data.length; i < j; i++) {
        ret.push(replaceAction(data[i]));
    }
    return ret.join("");
    function replaceAction(object){
        return temp.replace(regexp || (/\\?\{([^}]+)\}/g), function(match, name){
            if (match.charAt(0) == '\\') return match.slice(1);
            return (object[name] != undefined) ? object[name] : '';
        });
    }
}

function getStringLength(str) {  
    str = str || '';
    var len = 0;    
    for (var i=0; i<str.length; i++) {    
        if (str.charCodeAt(i)>127 || str.charCodeAt(i)==94) {    
             len += 2;    
         } else {    
             len ++;    
         }    
     }    
    return len;    
}    

function shortHash(str, number) {
    let arrL = str.substring(0, number);    
    let arrR = str.substring(str.length - number, str.length);
    return arrL + '....' + arrR;    
}    

function isEmailType(v){
    let reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
    return reg.test(v);
}


export {
    timeFormat,
    dateFormat,
    numberFormat,
    subs,
    getStringLength,
    shortHash,
    isEmailType
}