import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { redirect, useNavigate } from "react-router-dom";

import bgImg from "./assets/bg.jpg";
import './ai.css'

export default function Homepage(){
	return (<>
        <div className="ai-header-wrapper">
            <div className="ai-header">
                <h1>Werewolves.ai</h1>
                <a href="https://t.me/werewolves_ai_bot" className="ai-btn">Meet Werewolves.ai</a>
            </div>
        </div>
        <div className="ai-body">
            <div className="ai-content">
                <p>A On-Chain Werewolves Game powered by generative Al. Players canchallenge each other or take on ChatGPT-4 through a Telegram botinterface. The game ensures player identity confidentiality with privateblockchain technology and is built on a smart contract, enabling anyone tohost the game and make bets.</p>
            </div>

            <img src={ bgImg } />

            <div className="ai-content">
                <a href="https://t.me/werewolves_ai_bot" className="ai-btn">Meet Werewolves.ai</a>
            </div>
        </div>
	</>)
}