import Web3 from 'web3'

import erc20 from './ERC20.json';
import CrossChainABI from './CrossChainContract.json';

const web3 = new Web3(Web3.givenProvider);

//测试USDT token 合约:
const addressUSDT = '0x4f34073d858d3e13EfBbC0C567D566D2C2EE603c';
const contractUSDT = new web3.eth.Contract(erc20.abi, addressUSDT);

//form location.queryString.to
const addressCharge = '0xa6Ad3B2168bbfd2B39F50441Bf65660B607D28D2';
const contractCharge = new web3.eth.Contract(CrossChainABI.abi, addressCharge);

console.log({ contractUSDT, contractCharge });

const unitPrice = Math.pow(10, 18);

const getValue = (v)=>{
    v = Math.round(v * Math.pow(10, 4)/unitPrice)/Math.pow(10, 4);
    return v;
}

const getBalance = (account, callback) => {
    contractUSDT.methods.balanceOf(account).call((err, value)=>{
        console.log('getBalance', err, value);
        let v = getValue(value);
        callback(v);
    });
}

//uint256 _amount,address _target) _amount就是数量， __target 为网⻚带过来的to地址
const deposit = (data, callback) => {
    const { account, amount, toAddress } = data;

    let limit = web3.utils.toWei(amount.toString(), 'ether');
        limit = web3.utils.toBN(limit);

    contractUSDT.methods.allowance(account, addressCharge).call((err, allowValue)=>{
        if(allowValue/unitPrice >= amount){
            contractCharge.methods.deposit(limit, toAddress).send({ from: account }).then((result)=>{
                console.log('deposit allowance', result);
                callback(result);
            }).catch((err)=>{
                
            });
        }else{
            contractUSDT.methods.approve(addressCharge, limit).send({ from: account }).then((result)=>{
                console.log('approve', result);
                contractCharge.methods.deposit(limit, toAddress).send({ from: account }).then((result)=>{
                    console.log('deposit approve', result);
                    callback(result);
                }).catch((err)=>{
                
                });
            }).catch((err)=>{

            });
        }
    });

    

}

export {
    getBalance,
    deposit
};