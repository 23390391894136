import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import * as Contract from './contract';

export const deposit = createAsyncThunk("balance/deposit", async (data) => {
    return await new Promise((resolve, reject) => {
        Contract.deposit(data, function(res){
            resolve(res);
        });
    });
});


const _adapter = createEntityAdapter();
const initialState = _adapter.getInitialState({
    blocks: [],
    userData: {},
    depositResult: {}
});

const chargeSlice = createSlice({
    name: 'charge',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(deposit.fulfilled, (state, action) => {
            let data = action.payload;
            state.depositResult = data;
        });
    }
});

export default chargeSlice.reducer;
