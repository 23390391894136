import React, { useEffect, useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  BrowserRouter, HashRouter,
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom";

import Charge from './charge/charge';
import Homepage from './homepage/homepage';

import './App.scss';

export default function App() {
    const dispatch = useDispatch(); 

    return (<>
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={ <Homepage /> } />
                <Route exact path="/recharge/" element={ <Charge /> } />
            </Routes>
        </BrowserRouter>
    </>);
}